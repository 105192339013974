import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { getTrendingDestinationsForFilter, getTrendingDestinationFilters } from './constants'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import { excludeNullOrUndefined } from 'checkout/utils'
import GeoContext from 'contexts/geoContext'
import { useDestinationMaxSavings } from 'home/hooks/useDestinationMaxSavings'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TrendingDestinationsCarousel from './TrendingDestinationsCarousel'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

export const DEFAULT_FILTER: App.DestinationSelectedFilters = 'hotel'

interface Props {
  title?: React.ReactNode;
}

function TrendingDestinations(props: Props) {
  const { title } = props
  const [selectedFilter, setSelectedFilter] = useState<App.DestinationSelectedFilters>(DEFAULT_FILTER)
  const { currentRegionCode } = useContext(GeoContext)
  const filters = getTrendingDestinationFilters(currentRegionCode)
  const trendingDestinations = getTrendingDestinationsForFilter(selectedFilter, currentRegionCode) ?? []

  const destinationIds = trendingDestinations.map(destination => destination.destinationId).filter(excludeNullOrUndefined)
  const [maxSavings, fetchingMaxSavings] = useDestinationMaxSavings(destinationIds, selectedFilter)

  return (
    <SectionContainer gap={32}>
      <LayoutContainer>
        <VerticalSpacer gap={12}>
          <Heading variant="heading2">
            {title ?? <>Immerse yourself in trending <i>destinations</i></>}
          </Heading>
          <HorizontalContainerWithFadedOverflow>
            <Group direction="horizontal" gap={4}>
              {filters.map((filter) => <FilterChip
                selected={filter.id === selectedFilter}
                onClick={() => setSelectedFilter(filter.id)}
                key={filter.id}
                size="medium"
              >
                {filter.title}
              </FilterChip>)}
            </Group>
          </HorizontalContainerWithFadedOverflow>
        </VerticalSpacer>
      </LayoutContainer>
      <TrendingDestinationsCarousel
        trendingDestinations={trendingDestinations}
        fetching={fetchingMaxSavings}
        maxSavings={maxSavings}
      />
    </SectionContainer>
  )
}

export default TrendingDestinations
