import { useEffect } from 'react'
import { fetchMaxSavings } from 'actions/HomepageActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { EmptyObject } from 'lib/object/objectUtils'

export function useDestinationMaxSavings(
  destinationIds: Array<string>,
  selectedFilter: App.DestinationSelectedFilters,
): [Record<string, number | undefined>, boolean] {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchMaxSavings(destinationIds, selectedFilter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter])

  const maxSavings = useAppSelector(state => state.destination.maxSavings[selectedFilter]) ?? EmptyObject
  const fetching = useAppSelector(state => state.destination.fetchingMaxSavings)

  return [maxSavings, fetching]
}
