import React, { useContext, useMemo } from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import { useParams } from 'react-router'
import { capitalise } from 'lib/string/stringUtils'
import { HOME_PAGE_OFFER_TYPES, SupportedDestinations } from 'home/constants'
import Pane from 'components/Common/Pane'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import { usePlaceByName } from 'hooks/usePlace'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import { themeClassName } from 'lib/theme/themeUtils'

interface Props {
  className?: string;
}

function HeroOfferCarouselSection(props: Props) {
  const { className } = props
  const { destination } = useParams<{ destination?: SupportedDestinations}>()
  const { currentRegionCode, geoStateName } = useContext(GeoContext)
  const destinationId = usePlaceByName(destination)?.id ?? ANYWHERE_PLACE_ID

  const filters = useMemo(() => {
    if (destination) {
      return {
        offerTypes: HOME_PAGE_OFFER_TYPES,
        limit: MAX_CAROUSEL_ITEM_COUNT,
        placeIds: [destinationId],
      }
    } else {
      return getHomeOfferListParams({
        region: currentRegionCode,
        subregion: geoStateName,
      })
    }
  }, [currentRegionCode, destination, destinationId, geoStateName])

  return (
    <Pane paletteType="inverse" className={className}>
      <Group direction="vertical" gap={24}>
        <LayoutContainer>
          {!destination && <Heading variant="heading2">
            Today's top <i>exclusive</i> offers
          </Heading>}
          {destination && (
            <Heading variant="heading2">
              Today's top exclusive <i>{capitalise(destination)}</i> offers
            </Heading>
          )}
        </LayoutContainer>
        <OfferListCarousel
          filters={filters}
          className={themeClassName('inverse')}
          tileStyle="card-medium"
        />
      </Group>
    </Pane>
  )
}

export default React.memo(HeroOfferCarouselSection)
