import { API_CALL } from 'actions/actionConstants'
import * as SearchService from 'api/search'
import { FETCH_MAX_SAVINGS } from './apiActionConstants'
import { unique } from 'lib/array/arrayUtils'
import { isActiveLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import { OFFER_TYPE_TOUR } from 'constants/offer'

export function fetchMaxSavings(
  placeIds: Array<string>,
  filter: App.DestinationSelectedFilters,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    let isLuxPlusMember = false
    if (filter === OFFER_TYPE_TOUR) isLuxPlusMember = isActiveLuxPlusMember(state)

    const missingIds = unique(placeIds.filter(id => !state.destination.maxSavings[filter]?.[id]))
    if (missingIds.length === 0) return

    dispatch({
      type: API_CALL,
      api: FETCH_MAX_SAVINGS,
      filter,
      request: () => SearchService.getDestinationMaxSavings(state.geo.currentRegionCode, missingIds, filter, isLuxPlusMember),
    })
  }
}
