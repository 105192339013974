import React, { useMemo, useEffect } from 'react'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import { LOOKING_FOR_SOMETHING_ELSE_CONTENT } from '../LookingForSomethingElse/constants'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import { OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'
import Places from 'constants/places'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getHighIntentTours } from 'selectors/recomendations/highIntentSelectors'
import { connect } from 'react-redux'
import { fetchPersonalisedTours } from 'actions/RecommendationActions'
import { selectHighIntentTourOfferIds, selectPersonalisedTourKey } from 'selectors/recomendations/personalisedTours'
import { EmptyArray } from 'lib/array/arrayUtils'
import { isLoggedIn } from 'selectors/accountSelectors'

const DEFAULT_FILTERS: App.OfferListFilters = {
  offerTypes: ['tour', 'tour_v2'],
  frontPageVisibleOnly: true,
  holidayTypes: ['!Cruises'],
  destinationId: Places.Anywhere.id,
}

interface Props {
  title?: React.ReactNode;
  highIntentTourOfferIds?: Array<string>
  personalisedTour?: App.RecommendationOfferState;
  isUserLoggedIn: boolean;
}

function TourOfferCarousel(props: Props) {
  const { title, highIntentTourOfferIds, personalisedTour, isUserLoggedIn } = props
  const dispatch = useAppDispatch()
  const isManualSortingEnabled = isManualSortingEnabledFor('tours')

  const offerListFilters = useMemo((): App.OfferListFilters => {
    if (isManualSortingEnabled) {
      return {
        offerTypes: [OFFER_TYPE_TOUR_V2],
        sortBy: 'vertical-tour',
        limit: MAX_CAROUSEL_ITEM_COUNT,
      }
    }

    return DEFAULT_FILTERS
  }, [isManualSortingEnabled])

  useEffect(() => {
    if (highIntentTourOfferIds) {
      dispatch(fetchPersonalisedTours(highIntentTourOfferIds))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highIntentTourOfferIds, isUserLoggedIn])

  const lereOfferList = useMemo<App.OfferList>(() => {
    return {
      key: 'lere-personalised-tours-on-homepage',
      offerIds: personalisedTour?.offers.map(o => o.offerId) ?? EmptyArray,
      fetching: !personalisedTour ? true : personalisedTour.state === 'loading',
    }
  }, [personalisedTour])

  const to = useMemo(() => {
    if (offerListFilters.destinationId && offerListFilters.destinationId !== ANYWHERE_PLACE_ID) {
      const query = {
        destinationId: offerListFilters.destinationId,
        destinationName: offerListFilters.destinationName ?? '',
      }
      return `/search/tours?${new URLSearchParams(query)}`
    }
    return LOOKING_FOR_SOMETHING_ELSE_CONTENT.tours.to
  }, [offerListFilters])

  return (
    <OfferCardCarouselSection
      filters={offerListFilters}
      title={title ?? <>Once-in-a-lifetime <i>tours</i></>}
      allLink="/tours"
      additionalFilters="tourLocations"
      defaultFilter={Places.Anywhere.id}
      tileStyle="card-medium"
      additionalOfferList={lereOfferList} // Note: here lereOfferList is adding the top3 recommendations to the filter list
    >
      <OfferCarouselLookingForSomethingElse to={to} />
    </OfferCardCarouselSection>
  )
}

function mapState(state: App.State) {
  const personalisedTourKey = selectPersonalisedTourKey(state)

  return {
    highIntentTourOfferIds: selectHighIntentTourOfferIds(state),
    highIntentTours: getHighIntentTours(state),
    personalisedTour: personalisedTourKey ? state.recommendations.personalisedTours[personalisedTourKey] : undefined,
    isUserLoggedIn: isLoggedIn(state),
  }
}

export default connect(mapState)(TourOfferCarousel)
