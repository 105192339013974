import { setExperiencePlace } from 'actions/ExperienceActions'
import Places, { getDefaultRegionPlace } from 'constants/places'
import GeoContext from 'contexts/geoContext'
import useExperienceSearchList from 'hooks/Experiences/useExperienceSearchList'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import { usePlaceById, usePlaceByCoords } from 'hooks/usePlace'
import usePrevious from 'hooks/usePrevious'
import { useContext, useEffect } from 'react'

function useSmartExperiencePlace() {
  const geo = useContext(GeoContext)
  const experiencePlace = useAppSelector(state => state.experience.experiencePlace)
  const dispatch = useAppDispatch()

  const selectedPlace = usePlaceById(experiencePlace.placeId)
  const geoPlace = usePlaceByCoords(geo.latitude, geo.longitude)
  const currentPlace = experiencePlace.type === 'currentLocation' ? geoPlace : selectedPlace
  const prevPlace = usePrevious(currentPlace)

  // need to know if we actually have experiences available for the current place
  const list = useExperienceSearchList({ placeId: currentPlace?.id, sortBy: 'recommended' })

  useEffect(() => {
    // a 'null' current place means we tried to fetch it and a place for that ID didn't exist.
    // So fallback to something we know exists
    if ((!list.fetching && list.ids.length === 0) || currentPlace === null) {
      // we didn't get any results for our current location, fallback to the next appropriate type
      // Types levels: Selected Place -> Current Region -> Anywhere
      const fallbackRegionPlace = getDefaultRegionPlace(geo.currentRegionCode)
      if (currentPlace?.id !== fallbackRegionPlace.id) {
        dispatch(setExperiencePlace('region', { regionCode: geo.currentRegionCode, userSelected: false }))
      } else {
        dispatch(setExperiencePlace('place', { placeId: Places.Anywhere.id, userSelected: false }))
      }
    }
    // eslint-disable-next-line
  }, [list])

  return currentPlace || prevPlace
}

export default useSmartExperiencePlace
