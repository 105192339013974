import React from 'react'
import TrendingDestinationsTile from './TrendingDestinationTile'
import FullWidthCarouselWithAnalytics from 'components/WithAnalytics/Carousel/FullWidthCarouselWithAnalytics'
import { mediaQueryUp } from 'components/utils/breakpoint'
import styled from 'styled-components'
import { TrendingDestination } from './constants'
import { EmptyObject } from 'lib/object/objectUtils'

const GridContainer = styled(FullWidthCarouselWithAnalytics)`
  > div {
    grid-template-rows: 1fr 1fr 1fr;

    ${mediaQueryUp.tablet} {
      grid-template-rows: unset;
    }
  }
`

interface Props {
  trendingDestinations: Array<TrendingDestination>;
  fetching?: boolean;
  maxSavings?: App.DestinationMaxSavings;
}

function TrendingDestinationsCarousel(props: Props) {
  const { trendingDestinations, maxSavings = EmptyObject, fetching } = props

  return <GridContainer snap="start" itemsPerArrow={4}>
    {trendingDestinations.map((trendingDestination, index) => {
      const { destinationId, name } = trendingDestination
      const saveUpTo = maxSavings[destinationId ?? '']

      return (
        <TrendingDestinationsTile
          key={name}
          trendingDestination={trendingDestination}
          savings={saveUpTo}
          fetchingMaxSavings={fetching}
          index={index}
        />
      )
    })}
  </GridContainer>
}

export default TrendingDestinationsCarousel
